import { Box } from 'components/primitives'
import { FC, ReactNode } from 'react'
import Navbar from './navbar'
import UnsupportedChainBanner from './UnsupportedChainBanner'
import { useRouter } from 'next/router'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  const router = useRouter()
  const isHomePage = router.pathname === '/'

  return (
    <>
      <Box
        css={{
          background: '$neutralBg',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}
      >
        <Box
          css={{
            maxWidth: isHomePage ? '100%' : 1920,
            mx: 'auto',
          }}
        >
          <Navbar />
          <UnsupportedChainBanner />
          <main>{children}</main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
