import { Fragment } from 'react'
import { Anchor, Box, Button, Flex, Text } from 'components/primitives'
import { Avatar } from 'components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faBars,
  faXmark,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'components/custom/Link'
import Image from 'next/image'

import { ConnectWalletButton } from 'components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from 'components/common/FullscreenModal'
import { useENSResolver, useMarketplaceChain } from 'hooks'
import Wallet from 'components/navbar/Wallet'
import { chains, nfts } from 'config/routes'
import { useAuthWallets } from 'hooks/auth/useAuthWallets'
import { useLogout } from 'hooks/auth/useLogout'
import PriceDisplayToggle from 'components/common/PriceDisplayToggle'

const HamburgerMenu = () => {
  const { ownershipWallet } = useAuthWallets()
  const isConnected = Boolean(ownershipWallet?.address)
  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
  } = useENSResolver(ownershipWallet?.address)
  const { logout } = useLogout()
  const { routePrefix } = useMarketplaceChain()

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
      color="gray3"
    >
      <FontAwesomeIcon icon={faBars} width={16} height={16} />
    </Button>
  )

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between"
        >
          <Link href="/" analyticsId="click marketplace home">
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image
                src="/pop-marketplace-logo.svg"
                width={150}
                height={35}
                alt="Proof of Play Marketplace"
              />
            </Box>
          </Link>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$gray3',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            {ownershipWallet && (
              <Link
                href={`/portfolio/${ownershipWallet.address || ''}`}
                legacyBehavior
              >
                <Flex
                  css={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    pb: '$4',
                  }}
                >
                  <Flex css={{ alignItems: 'center' }}>
                    {ensAvatar ? (
                      <Avatar
                        size="medium"
                        src={ensAvatar}
                        fallback={
                          <Jazzicon
                            diameter={36}
                            seed={jsNumberForAddress(
                              ownershipWallet.address as string
                            )}
                          />
                        }
                      />
                    ) : (
                      <Jazzicon
                        diameter={36}
                        seed={jsNumberForAddress(
                          ownershipWallet.address as string
                        )}
                      />
                    )}
                    <Text style="subtitle1" css={{ ml: '$2' }}>
                      {shortEnsName ? shortEnsName : shortAddress}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
            )}

            {Object.keys(nfts).map((key) =>
              Object.keys(nfts[key].chains).length > 1 ? (
                <Fragment key={`title-${nfts[key].name}`}>
                  <Text
                    style="subtitle1"
                    css={{
                      borderBottom: '1px solid $gray4',
                      cursor: 'pointer',
                      pb: '$4',
                      pt: '24px',
                    }}
                  >
                    {nfts[key].name}
                  </Text>

                  {Object.keys(nfts[key].chains).map((chain) => (
                    chains[chain] ? (
                      <Link
                        key={`link-${key}-${chain}`}
                        href={`/nft/${key}?chain=${chain}`}
                      >
                        <Text style="subtitle1">{chains[chain].name}</Text>
                      </Link>
                    ) : null
                  ))}
                </Fragment>
              ) : (
                <Link
                  key={`link-${nfts[key].name}`}
                  href={`/nft/${key}?chain=${Object.keys(nfts[key].chains)[0]}`}
                >
                  <Text
                    style="subtitle1"
                    css={{
                      borderBottom: '1px solid $gray4',
                      cursor: 'pointer',
                      pb: '$4',
                      pt: '24px',
                    }}
                  >
                    {nfts[key].name}
                  </Text>
                </Link>
              )
            )}
            <Wallet />
            <Flex
              css={{
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
                borderBottom: '1px solid $gray4',
              }}
              onClick={() => logout()}
            >
              <Text
                style="subtitle1"
                css={{
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Logout
              </Text>
              <Box css={{ color: '$gray10' }}>
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  width={16}
                  height={16}
                />
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}
          >
            <Flex direction="column">
              <Link href="/" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>
              <Link
                href={`/portfolio/${ownershipWallet?.address || ''}`}
                legacyBehavior
              >
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Portfolio
                </Text>
              </Link>
              <Anchor
                href="https://docs.reservoir.tools/docs"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Docs</Text>
              </Anchor>
            </Flex>
            <Box>
              <ConnectWalletButton />
            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <a href="https://twitter.com/reservoir0x" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faTwitter} width={20} height={20} />
            </Button>
          </a>
          <PriceDisplayToggle />
          {/* <ThemeSwitcher /> */}
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}

export default HamburgerMenu
