import CryptoCurrencyIcon from 'components/primitives/CryptoCurrencyIcon'
import {
  Box,
  Button,
  Flex,
  FormatCrypto,
  FormatCurrency,
  Text,
} from 'components/primitives'
import { mainnet } from 'wagmi/chains'
import { useBalance, useReadContracts } from 'wagmi'
import { useMemo, useState } from 'react'
import { zeroAddress, formatUnits, erc20Abi, Address } from 'viem'
import { useCoinConversion } from '@reservoir0x/reservoir-kit-ui'
import { currencies } from 'config/currencies'
import Unwrap from './Unwrap'
import { useAuthWallets } from 'hooks/auth/useAuthWallets'

type EnhancedCurrency = (typeof currencies)[0] & {
  usdPrice: number
  balance: string | number | bigint
  weth?: `0x${string}`
}

const nonNativeCurrencies = currencies.filter(
  (currency) => currency.address !== zeroAddress,
)

const currencySymbols = currencies.map((currency) => currency.symbol).join(',')
const currencyCoingeckoIds = currencies
  .map((currency) => currency.coinGeckoId)
  .join(',')

type ChainBalances = {
  [chainId: number]: {
    data?: { value: bigint } | undefined
  }
}

const Wallet = () => {
  const [viewAll, setViewAll] = useState(false)
  const { ownershipWallet } = useAuthWallets()
  const { data: nonNativeBalances, queryKey: nonNativeBalancesQueryKey } =
    useReadContracts({
      contracts: nonNativeCurrencies.map((currency) => ({
        abi: erc20Abi,
        address: currency.address as `0x${string}`,
        chainId: currency.chain.id,
        functionName: 'balanceOf',
        args: [ownershipWallet?.address as any],
      })),
      allowFailure: true,
      blockTag: 'latest',
      query: {
        enabled: ownershipWallet?.address ? true : false,
        staleTime: 1000,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    })

  //CONFIGURABLE: Configure these by just changing the chainId to fetch native balance info, in addition to changing this
  // also make sure you change the enhancedCurrencies function to take into account for these new balances
  const ethBalance = useBalance({
    address: ownershipWallet?.address as Address,
    chainId: mainnet.id,
    blockTag: 'latest',
    query: {
      staleTime: 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  })

  // Update the chainBalances declaration
  const chainBalances: ChainBalances = currencies
    .filter(curr => curr.address === zeroAddress && curr.chain.id !== mainnet.id)
    .reduce((acc, curr) => ({
      ...acc,
      [curr.chain.id]: useBalance({
        address: ownershipWallet?.address as Address,
        chainId: curr.chain.id,
        blockTag: 'latest',
        query: {
          staleTime: 1000,
          refetchOnMount: true,
          refetchOnWindowFocus: true,
        },
      })
    }), {} as ChainBalances)

  const usdConversions = useCoinConversion(
    'USD',
    currencySymbols,
    currencyCoingeckoIds,
  )

  const enhancedCurrencies = useMemo(() => {
    const currencyToUsdConversions = usdConversions.reduce(
      (map, data) => {
        map[data.symbol] = data
        map[(data as any).coinGeckoId] = data
        return map
      },
      {} as Record<string, (typeof usdConversions)[0]>,
    )

    return currencies.map((currency, i) => {
      let balance: string | number | bigint = 0n
      if (currency.address === zeroAddress) {
        //CONFIGURABLE: Configure these to show the fetched balance results configured above in the useBalance hooks
        switch (currency.chain.id) {
          case mainnet.id: {
            balance = ethBalance.data?.value || 0n
            break
          }
          default: {
            balance = chainBalances[currency.chain.id]?.data?.value || 0n
            break
          }
        }
      } else {
        const index = nonNativeCurrencies.findIndex(
          (nonNativeCurrency) =>
            nonNativeCurrency.chain.id === currency.chain.id &&
            nonNativeCurrency.symbol === currency.symbol &&
            nonNativeCurrency.coinGeckoId === currency.coinGeckoId,
        )
        balance =
          nonNativeBalances &&
          nonNativeBalances[index] &&
          (typeof nonNativeBalances[index] === 'string' ||
            typeof nonNativeBalances[index] === 'number' ||
            typeof nonNativeBalances[index] === 'bigint')
            ? (nonNativeBalances[index] as string | number | bigint)
            : // @ts-ignore: viem changed types i think?
              (nonNativeBalances?.[index]?.result ?? 0n)
      }

      const conversion =
        currencyToUsdConversions[
          currency.coinGeckoId.length > 0
            ? currency.coinGeckoId
            : currency.symbol.toLowerCase()
        ]
      const usdPrice =
        Number(formatUnits(BigInt(balance), currency?.decimals || 18)) *
        (conversion?.price || 0)
      return {
        ...currency,
        usdPrice,
        balance,
      }
    }) as EnhancedCurrency[]
    //CONFIGURABLE: Configure these to regenerate whenever a native balance changes, non native balances are already handled
  }, [usdConversions, nonNativeBalances, ethBalance.data, chainBalances])

  const totalUsdBalance = useMemo(() => {
    return enhancedCurrencies.reduce(
      (total, { usdPrice }) => total + usdPrice,
      0,
    )
  }, [enhancedCurrencies])

  const visibleCurrencies = viewAll
    ? enhancedCurrencies
    : enhancedCurrencies.slice(0, 4)

  return (
    <Flex
      direction="column"
      align="center"
      css={{
        background: '$gray2',
        border: '1px solid $gray3',
        borderRadius: 8,
        mt: '$3',
      }}
    >
      <Box css={{ width: '100%', height: 1, background: '$gray1' }}></Box>
      <Flex direction="column" align="center" css={{ p: '$4', width: '100%' }}>
        <Text style="body2" color="subtle" css={{ mb: '$2', mt: '$2' }}>
          Total Balance
        </Text>
        <FormatCurrency
          style="h4"
          amount={totalUsdBalance}
          css={{ mb: '$4' }}
        />
        {visibleCurrencies.map((currency, i) => {
          return (
            <Flex
              key={i}
              css={{ width: '100%', mt: 28, gap: '$3' }}
              align="center"
            >
              <Flex
                css={{
                  width: 40,
                  height: 40,
                  background: '$gray3',
                  borderRadius: 4,
                  flexShrink: 0,
                }}
                align="center"
                justify="center"
              >
                <CryptoCurrencyIcon
                  address={currency.address}
                  chainId={currency.chain.id}
                  css={{ height: 24 }}
                />
              </Flex>
              <Flex direction="column" justify="center" css={{ width: '100%' }}>
                <Flex justify="between">
                  <Text style="body1">{currency.symbol}</Text>
                  {currency.symbol === 'WETH' && (
                    <Unwrap currency={currency} mode="unwrap" />
                  )}
                  {currency.symbol === 'ETH' && (
                    <Unwrap currency={currency} mode="wrap" />
                  )}
                  <FormatCrypto
                    amount={currency.balance}
                    decimals={currency.decimals}
                    textStyle="body1"
                  />
                </Flex>
                <Flex justify="between">
                  <Text style="body2" color="subtle">
                    {currency.chain.name}
                  </Text>
                  <Text style="body2" color="subtle"></Text>
                  <FormatCurrency amount={currency.usdPrice} />
                </Flex>
              </Flex>
            </Flex>
          )
        })}
        <Button
          css={{
            width: '100%',
            justifyContent: 'center',
            mt: 24,
            mb: '$3',
          }}
          color="gray3"
          onClick={() => {
            setViewAll(!viewAll)
          }}
        >
          View {viewAll ? 'Fewer' : 'All'} Tokens
        </Button>
      </Flex>
    </Flex>
  )
}

export default Wallet
