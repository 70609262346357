import { usePrivy, useLogin, PrivyClientConfig } from "@privy-io/react-auth";

const ENVIRONMENT = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT ?? "";

// TODO:
// import { analytics } from "~~/helpers";

const DEFAULT_LOGIN_METHODS: PrivyClientConfig["loginMethods"] = [
    "wallet",
    "google",
    "discord",
    // Include email for testnet
    ...(ENVIRONMENT === "testnet" ? (["email"] as const) : []),
];

type LoginParams = {
    overrideLoginMethods?: PrivyClientConfig["loginMethods"];
};

export const useAuthLogin = () => {
    const {
        authenticated: isAuthenticated,
        isModalOpen,
        ready: isReady,
    } = usePrivy();
    const isComplete = !isReady ? null : isAuthenticated;
    const isDisabled = !isReady || (isReady && isAuthenticated);

    const { login: privyLogin } = useLogin({
        onComplete: ({ isNewUser }) => {
            if (isNewUser) {
                // void analytics
                //     .trackWebEvent("privy - continue with a wallet", {
                //         properties: user.wallet?.walletClientType ?? "unknown",
                //     })
                //     .catch(console.error);
            }
        },
        onError: console.error,
    });

    const login = (params: LoginParams = {}) => {
        const { overrideLoginMethods } = params;
        privyLogin({
            loginMethods: overrideLoginMethods || DEFAULT_LOGIN_METHODS,
        });
    };

    return {
        isComplete,
        isDisabled,
        isReady,
        isModalOpen,
        login,
    };
};
