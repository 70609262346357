import React from 'react'
import Link from 'next/link'
import { Flex } from 'components/primitives'
import NavItem from 'components/navbar/NavItem'
import { nfts } from 'config/routes'

const NftNavItems = () => {
  return (
    <Flex
      align="center"
      css={{
        gap: '$5',
        ml: '$5',
      }}
    >
      {Object.keys(nfts).map((systemId) => (
        <Link key={systemId} href={`/nft/${systemId}`}>
          <NavItem
            analyticsId={`click marketplace ${nfts[
              systemId
            ].name.toLowerCase()}`}
            systemId={systemId}
          >
            {nfts[systemId].name}
          </NavItem>
        </Link>
      ))}
    </Flex>
  )
}

export default NftNavItems
